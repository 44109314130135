<template>
  <div id="savings-overview">
    <div v-if="hasCompleteSaving">
      <div class="d-flex justify-content-around">
        <div class="d-flex flex-column align-items-center mr-3">
          <span>Revenue</span>
          <h4 class="text-success font-weight-bolder">
            {{ formatCurrency(adjustedRevenue) }}
          </h4>
        </div>
        <div class="d-flex flex-column align-items-center">
          <span>Savings</span>
          <h4>{{ formatCurrency(saving.totalSavings) }}</h4>
        </div>
        <div class="d-flex flex-column align-items-center">
          <span>Switches</span>
          <h4>{{ savingsObj.SWITCHES }}</h4>
        </div>
      </div>

      <!-- Loop through savings area > supplies -->
      <div v-for="(data, type) in savingsObj.REPORT" :key="type" class="pb-2 position-relative">
        <div class="savings-area-top-right">
          <!-- Request bills -->
          <b-badge
            v-if="areasThatNeedBills.includes(type)"
            variant="warning"
            class="border"
            v-b-popover.hover.top="'High spend and not enough data'"
            >Request bills</b-badge
          >
        </div>

        <!-- No quotes -->
        <b-row
          v-if="savingsObj.TYPENOQUOTES[type]"
          class="pl-3 py-1 border-top bg-grey d-flex align-items-center border-bottom"
        >
          <span class="text-uppercase">{{ formatSupplyType(type) }}</span>
          <span class="ml-2 reducer-label"> {{ formatCobold(savingsObj.TYPENOQUOTES[type]) }}</span>
        </b-row>

        <!-- Recommended deals -->
        <div v-else>
          <b-row class="bg-grey py-1 border-top" v-b-toggle="`accordion-${type}`">
            <b-col class="text-uppercase pl-3 align-items-center">
              <span>
                {{ formatSupplyType(type) }}
              </span>
              <span v-if="savingsObj.TYPECOMMISION[type]" class="ml-1 reducer-label text-lowercase">
                {{ formatCurrency(savingsObj.TYPECOMMISION[type]) }} IN REVENUE</span
              >
            </b-col>
          </b-row>
          <b-collapse :id="`accordion-${type}`" visible>
            <b-row
              v-for="s in data.supplies"
              :key="s.supplyId"
              :class="['border-top py-2', { 'd-none': !s.recommendedDeal }]"
            >
              <template v-if="s.recommendedDeal">
                <b-col cols="12">
                  <div class="d-flex justify-content-between">
                    <b-link
                      class="link-to-report"
                      :to="{
                        name: 'savings',
                        params: { organisationId: s.organisationId },
                        query: { scroll: s.supplyId }
                      }"
                      target="_blank"
                      v-b-popover.hover.right="'View in report'"
                    >
                      {{ s.name ? s.name : "Site" }}
                      <span v-if="isWon(s.supplyId)" class="text-success reducer-label pl-1"
                        >Closed Won</span
                      >
                      <span v-if="isLost(s.supplyId)" class="text-danger reducer-label pl-1"
                        >Closed Lost</span
                      >
                      <font-awesome-icon :icon="['fas', 'external-link']" class="ml-1 d-none" />
                    </b-link>
                    <!-- Select meter won/lost -->
                    <b-dropdown variant="outline-secondary" size="sm">
                      <b-dropdown-item
                        v-if="!isWon(s.supplyId)"
                        @click="markAsClosedWon(s.supplyId)"
                        >Closed won
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="!isLost(s.supplyId)"
                        @click="markAsClosedLost(s.supplyId)"
                        >Closed lost
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="isLost(s.supplyId) || isWon(s.supplyId)"
                        @click="clearMeterStatus(s.supplyId)"
                        >Clear meter status
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
                <b-col class="d-flex justify-content-between">
                  <div class="details-field">
                    <label
                      v-if="isLost(s.supplyId) || isWon(s.supplyId)"
                      class="text-strike-through"
                      >Revenue</label
                    >
                    <label v-else>Revenue</label>
                    <span class="font-weight-bold">{{ text(formatCurrency(s.commission)) }}</span>
                  </div>

                  <div class="details-field">
                    <label>Savings</label>
                    <span>{{ text(formatCurrency(s.saving)) }}</span>
                  </div>

                  <div class="details-field">
                    <label>TERM</label>
                    <span>{{ s.recommendedDeal.contractTerm / 12 }} year</span>
                  </div>

                  <div class="details-field">
                    <label>CED</label>
                    <span>{{
                      s.currentDeal.outOfContract
                        ? "OOC"
                        : text(formatDate(s.currentDeal.contractExpiry))
                    }}</span>
                  </div>

                  <div v-if="s.tender" class="details-field">
                    <label>Tender</label>
                    <span>{{ s.tender ? "Yes" : "--" }}</span>
                  </div>

                  <div class="details-field">
                    <label>Risk Level</label>
                    <span v-if="!s.recommendedDeal.details">--</span>
                    <span v-else>{{ riskLevelText(s.recommendedDeal.details.riskLevel) }}</span>
                  </div>
                </b-col>
                <b-col
                  v-if="s.recommendedDeal.details && s.recommendedDeal.details.riskLevelText"
                  cols="12"
                >
                  <label class="reducer-label m-0">Comment</label>
                  <span class="d-block smaller">{{ s.recommendedDeal.details.riskLevelText }}</span>
                </b-col>
              </template>
            </b-row>
          </b-collapse>
        </div>
      </div>
    </div>
    <div v-else>
      <h5 class="text-center p-2">No complete reports</h5>
    </div>
  </div>
</template>

<script>
import SupplyTypeHelper from "@/helper/supplytypehelper";
import FormatHelper from "@/helper/formathelper";
import Savings from "@/helper/savings";

export default {
  name: "SavingsOverview",
  props: {
    saving: {
      type: Object,
      required: false
    },
    areasThatNeedBills: {
      type: Array,
      required: false,
      default: () => []
    },
    salesRecord: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      closedLostMeters: this.salesRecord.closedLostMeters ? this.salesRecord.closedLostMeters : [],
      closedWonMeters: this.salesRecord.closedWonMeters ? this.salesRecord.closedWonMeters : [],
      closedLostWonOptions: [
        { value: null, text: "Close Meter" },
        { value: "closedWon", text: "Closed Won" },
        { value: "closedLost", text: "Closed Lost" }
      ]
    };
  },
  computed: {
    hasCompleteSaving() {
      return this.saving && this.saving.state === "complete";
    },
    savingsObj() {
      let savings = null;
      if (this.hasCompleteSaving) {
        try {
          let savingsObject = new Savings(SupplyTypeHelper.supplyTypes(), this);
          savingsObject.updateDeals(this.saving.suppliesMap, this.saving.dealsMap, true);
          let tempSavings = savingsObject.savings;
          savingsObject.sortSuppliesAndDeals(tempSavings);
          savings = tempSavings;

          savings.REPORT = Object.fromEntries(
            Object.entries(savings.REPORT).sort((a, b) => {
              let commsA = a[1].commission ? a[1].commission : 0;
              let commsB = b[1].commission ? b[1].commission : 0;
              let savA = a[1].saving ? a[1].saving : 0;
              let savB = b[1].saving ? b[1].saving : 0;
              return commsB - commsA || savB - savA;
            })
          );
        } catch (error) {
          console.log(error);
        }
      }
      return savings;
    },
    adjustedRevenue() {
      let revenue = this.saving.totalCommission;
      if (this.closedLostMeters.length || this.closedWonMeters.length) {
        this.closedLostMeters
          .concat(this.closedWonMeters)
          .forEach(m => (revenue -= this.saving.supplyCommission[m]));
      }
      return revenue;
    }
  },
  methods: {
    formatSupplyType: SupplyTypeHelper.toDisplay,
    supplyTypes: SupplyTypeHelper.supplyTypes,
    formatCurrency: FormatHelper.formatPoundToDisplay,
    formatCobold: FormatHelper.formatCoboldCase,
    formatDate: FormatHelper.formatDate,
    text: t => (t ? t : "--"),
    riskLevelText: r => {
      switch (r) {
        case 1:
          return "Green";
        case 2:
          return "Amber";
        case 3:
          return "Red";
        default:
          return "--";
      }
    },
    markAsClosedLost(supplyId) {
      if (this.isWon(supplyId)) {
        this.closedWonMeters = this.closedWonMeters.filter(id => id !== supplyId);
      }
      if (!this.isLost(supplyId)) {
        this.closedLostMeters.push(supplyId);
      }
      this.saveMeterStatus();
    },
    markAsClosedWon(supplyId) {
      if (this.isLost(supplyId)) {
        this.closedLostMeters = this.closedLostMeters.filter(id => id !== supplyId);
      }
      if (!this.isWon(supplyId)) {
        this.closedWonMeters.push(supplyId);
      }
      this.saveMeterStatus();
    },
    clearMeterStatus(supplyId) {
      this.closedWonMeters = this.closedWonMeters.filter(id => id !== supplyId);
      this.closedLostMeters = this.closedLostMeters.filter(id => id !== supplyId);
      this.saveMeterStatus();
    },
    saveMeterStatus() {
      let newSalesRecord = { ...this.salesRecord };
      newSalesRecord.closedLostMeters = this.closedLostMeters;
      newSalesRecord.closedWonMeters = this.closedWonMeters;
      this.$emit("update-details", newSalesRecord);
    },
    isWon(supplyId) {
      return this.closedWonMeters.includes(supplyId);
    },
    isLost(supplyId) {
      return this.closedLostMeters.includes(supplyId);
    }
  }
};
</script>

<style lang="scss">
#savings-overview {
  padding-bottom: 0 !important;
  .details-field {
    font-size: 15px;
    flex: 1 1 0;
    width: 0;
    label {
      margin-bottom: 0;
    }
    padding: 0.25rem 0;
    display: flex;
    flex-flow: column;
  }
  .savings-area-top-right {
    position: absolute;
    top: 2px;
    right: 0px;
    z-index: 999;
    .badge {
      font-size: 10px;
      padding: 5px;
    }
  }
  .smaller {
    font-size: 14px;
  }
  .bg-grey {
    background: whitesmoke;
  }
  .link-to-report {
    color: inherit;
    text-decoration: none;
    padding: 5px;
    border-radius: 5px;
    margin-left: -5px;
    &:hover {
      background: #e4e4e4;
      svg {
        display: inline !important;
        color: #b8b8b8;
        font-size: 15px;
      }
    }
  }
  .closed-meter-select {
    max-width: 120px;
  }
}
</style>
