<template>
  <div id="sales-record-links">
    <div class="d-flex justify-content-end">
      <b-input
        v-model="newLink"
        size="sm"
        class="reducer-input w-25"
        placeholder="Organisation/Deal ID"
      ></b-input>
      <b-button
        :disabled="!newLink"
        size="sm"
        variant="primary"
        class="reducer-btn"
        @click="$emit('add-link', newLink)"
      >
        Add new link
      </b-button>
    </div>
    <b-table
      class="mt-1"
      ref="recordsTable"
      :items="salesRecords"
      :fields="fields"
      sort-desc-first
      responsive
    >
      <template #cell(organisationId)="row">
        <b-dropdown class="mt-2" lazy size="sm" variant="outline-secondary">
          <!-- <organisation-tabs
            v-if="row.item.firstUserEmail"
            :organisation-id="row.value"
            :archived="row.item.status == 'ARCHIVED'"
            :links="['savings']"
          />
          <b-dropdown-divider v-if="row.item.firstUserEmail" /> -->
          <b-dropdown-item
            :to="{
              name: 'sales-record',
              params: { organisationId: row.value }
            }"
            target="_blank"
          >
            <font-awesome-icon :icon="['fas', 'chart-network']" class="text-muted mr-2" />Deal
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('remove-link', row.value)">
            <font-awesome-icon :icon="['fas', 'trash']" class="text-muted mr-1 pr-1" />
            Unlink
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(stageId)="row">
        <SalesRecordStageSelect
          :stage="row.item.stage"
          :closed-lost-reason="row.item.closedLostReason"
          disabled
        />
      </template>

      <template #cell(organisationName)="row">
        <span>{{ row.value }}</span>
        <b-badge v-if="!row.item.firstUserEmail" variant="danger" class="p-1 ml-2"
          >Org Deleted</b-badge
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import SalesRecordStageSelect from "./SalesRecordStageSelect";

export default {
  name: "SalesRecordLinks",
  components: {
    SalesRecordStageSelect
    // OrganisationTabs
  },
  props: {
    salesRecords: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newLink: null,
      fields: [
        { key: "selected", label: "" },
        { key: "organisationId", label: "" },
        {
          key: "organisationName",
          label: "Name",
          formatter: FormatHelper.orgShortName,
          sortable: true
        },
        { key: "project", label: "Project", sortable: true },
        {
          key: "cpmEmail",
          label: "CPM",
          formatter: FormatHelper.displayNameFromEmail,
          sortable: true
        },
        // {
        //   key: "apmEmail",
        //   label: "APM",
        //   formatter: FormatHelper.displayNameFromEmail,
        //   sortable: true
        // },
        // {
        //   key: "opsEmail",
        //   label: "Ops",
        //   formatter: FormatHelper.displayNameFromEmail,
        //   sortable: true
        // },
        // {
        //   key: "totalCommission",
        //   label: "Revenue",
        //   formatter: FormatHelper.formatPoundToDisplay,
        //   sortable: true
        // },
        // {
        //   key: "totalSavings",
        //   label: "Savings",
        //   formatter: FormatHelper.formatPoundToDisplay,
        //   sortable: true
        // },
        {
          key: "totalAQ",
          label: "Total AQ",
          formatter: Math.round,
          sortable: true
        },
        {
          key: "soonestCED",
          label: "Soonest CED",
          formatter: FormatHelper.formatDate,
          sortable: true
        },
        { key: "firstUserEmail", label: "First User", sortable: true },
        // {
        //   key: "nextActionDate",
        //   label: "Next Action",
        //   formatter: FormatHelper.formatDate,
        //   sortable: true
        // },
        // {
        //   key: "viewed",
        //   label: "Report View",
        //   formatter: FormatHelper.formatDate,
        //   sortable: true
        // },
        // {
        //   key: "closedLostDate",
        //   label: "Closed Lost",
        //   formatter: FormatHelper.formatDate,
        //   sortable: true
        // },
        // {
        //   key: "closedWonDate",
        //   label: "Closed Won",
        //   formatter: FormatHelper.formatDate,
        //   sortable: true
        // },
        {
          key: "stageId",
          label: "Stage",
          sortable: true
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#organisation-contracts {
  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
