<template>
  <div id="sales-record-details">
    <div class="sales-link">
      <b-link :to="{ name: 'sales', params: { cpmId: salesRecord.cpmId } }">
        <font-awesome-icon class="mr-2" :icon="['fas', 'chevron-left']" />
        {{ cpmName }}'s Deals
      </b-link>
    </div>

    <b-row
      align-h="between"
      align-v="center"
      class="px-3 flex-wrap-reverse
    "
    >
      <h3 class="mb-1 p-1" :title="salesRecord.organisationName">
        {{ orgShortName(salesRecord.organisationName) }}
      </h3>
      <div class="d-flex align-items-center">
        <CreditScoreBadge
          v-if="salesRecord.organisation"
          :score="salesRecord.organisation.creditScore"
          class="mr-2 mb-1"
        />
        <SalesRecordStageSelect
          :stage="salesRecord.stage"
          :closed-lost-reason="salesRecord.closedLostReason"
          @change-stage="updateStage"
          @change-closed-lost="updateClosedLost"
          @change-closed-won="updateClosedWon"
        />

        <b-badge
          v-if="salesRecord.stage === 'CLOSED_LOST'"
          class="reducer-badge ml-2 border pointer closed-lost-reason-badge"
          @click="selectingClosedLostDate = true"
          @keydown.enter="updateClosedLostDate"
        >
          <b-input v-if="selectingClosedLostDate" v-model="closedLostDate" type="date" size="sm" />
          <template v-else-if="salesRecord.closedLostDate">{{
            formatSimpleDate(salesRecord.closedLostDate)
          }}</template>
          <template v-else>Select closed lost date</template>
        </b-badge>

        <ClosedLostReasonSelect
          v-if="salesRecord.stage === 'CLOSED_LOST'"
          class="ml-2"
          :closed-lost-reason="salesRecord.closedLostReason"
          @change-reason="updateClosedLostReason"
        />

        <b-badge
          v-if="salesRecord.stage === 'CLOSED_WON'"
          class="reducer-badge ml-2 border pointer closed-won-reason-badge"
          @click="selectingClosedWonDate = true"
          @keydown.enter="updateClosedWonDate"
        >
          <b-input v-if="selectingClosedWonDate" v-model="closedWonDate" type="date" size="sm" />
          <template v-else-if="salesRecord.closedWonDate">{{
            formatSimpleDate(salesRecord.closedWonDate)
          }}</template>
          <template v-else>Select closed won date</template>
        </b-badge>

        <b-badge
          class="reducer-badge ml-2 border pointer bg-white bg-hover-white py-2"
          @click="selectingNextActions = true"
          @keydown.enter="updateNextActionDate"
        >
          <b-input v-if="selectingNextActions" v-model="nextActionDate" type="date" size="sm" />
          <template v-else-if="salesRecord.nextActionDate"
            >Date of next action: {{ formatSimpleDate(salesRecord.nextActionDate) }}</template
          >
          <template v-else>Select date of next action</template>
        </b-badge>
      </div>
    </b-row>
    <b-row>
      <b-col cols="4">
        <editable-field
          v-model="salesRecord.project"
          label="Project"
          @update="updateField('project', $event)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <editable-field
          v-model="salesRecord.contactName"
          label="Contact"
          @update="updateField('contactName', $event)"
        />
        <div class="details-field">
          <label>First User</label>
          <span>{{
            text(salesRecord.organisation ? salesRecord.organisation.firstUserEmail : null)
          }}</span>
        </div>
        <editable-field
          v-model="salesRecord.cpmId"
          :options="cpms"
          label="CPM"
          :text-formatter="displayNameFromEmail"
          value-field="userId"
          :display-text="displayNameFromEmail(salesRecord.cpmEmail)"
          @update="updateCpmEmail"
        />
      </b-col>

      <b-col>
        <editable-field
          v-model="salesRecord.contactNumber"
          label="Phone Number"
          type="number"
          @update="updateField('contactNumber', $event)"
        />

        <editable-field
          class="mb-3"
          v-model="salesRecord.accountantName"
          label="Accountant"
          @update="updateField('accountantName', $event)"
        />

        <editable-field
          v-model="salesRecord.apmId"
          :options="apms"
          label="APM"
          :text-formatter="displayNameFromEmail"
          value-field="userId"
          :display-text="displayNameFromEmail(salesRecord.apmEmail)"
          @update="updateApmEmail"
        />
      </b-col>
      <b-col>
        <editable-field
          :value="salesRecord.contactEmail"
          label="Email Address"
          @update="updateField('contactEmail', $event)"
          type="email"
        />

        <div class="details-field two-lines">
          <label>
            Report View
            <font-awesome-icon
              v-b-popover.hover.top="'Excludes accountant views'"
              class="ml-"
              :icon="['far', 'question-circle']"
            />
          </label>
          <template v-if="latestReportView">
            <span>{{ text(formatDate(latestReportView.created)) }}</span>

            <!-- Link to user dashboard -->
            <b-link
              v-if="latestReportView.userId"
              class="small text-muted"
              v-b-tooltip="'Go to user dashboard'"
              :to="{ path: '/dashboard?userid=' + latestReportView.userId }"
              target="_blank"
            >
              <i>
                {{ latestReportView.userEmail }}
                <font-awesome-icon class="ml-1 rotate-45" :icon="['far', 'long-arrow-up']" /> </i
            ></b-link>

            <!-- Regular text if no user id in event -->
            <span v-else class="small text-muted" :title="latestReportView.userEmail"
              ><i>{{ latestReportView.userEmail }}</i></span
            >
          </template>
          <span v-else>--</span>
        </div>

        <editable-field
          v-model="salesRecord.opsId"
          :options="ops"
          label="Ops"
          :text-formatter="displayNameFromEmail"
          value-field="userId"
          :display-text="displayNameFromEmail(salesRecord.opsEmail)"
          @update="updateOpsEmail"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import TypeHelper from "@/helper/typehelper";
import EditableField from "@/components/EditableField";
import SalesRecordStageSelect from "@/components/sales/SalesRecordStageSelect";
import ClosedLostReasonSelect from "@/components/sales/ClosedLostReasonSelect";
import CreditScoreBadge from "@/components/CreditScoreBadge";

export default {
  name: "SalesRecordDetails",
  components: {
    EditableField,
    SalesRecordStageSelect,
    ClosedLostReasonSelect,
    CreditScoreBadge
  },
  props: {
    salesRecord: {
      type: Object,
      required: true
    },
    saving: {
      type: Object,
      required: false
    },
    cpms: {
      type: Array,
      required: true
    },
    apms: {
      type: Array,
      required: true
    },
    ops: {
      type: Array,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    latestCall: Object
  },
  data() {
    return {
      newSalesRecord: { ...this.salesRecord },
      selectingNextActions: false,
      selectingClosedLostDate: false,
      selectingClosedWonDate: false,
      closedWonDate: null,
      closedLostDate: null,
      nextActionDate: null
    };
  },
  computed: {
    cpmName() {
      return FormatHelper.displayNameFromEmail(this.salesRecord.cpmEmail).split(" ")[0];
    },
    accountantDomain() {
      let domain = "";
      if (this.salesRecord.organisation) {
        domain = this.salesRecord.organisation.firstUserEmail.split("@")[1];
      }
      return domain;
    },
    firstReportView() {
      return this.events
        .slice()
        .reverse()
        .filter(e => e.userEmail && !e.userEmail.includes(this.accountantDomain))
        .find(e => e.type === "reportOpened");
    },
    latestReportView() {
      return this.events
        .filter(e => e.userEmail && !e.userEmail.includes(this.accountantDomain))
        .find(e => e.type === "reportOpened");
    },
    latestReportShare() {
      return this.events.find(e => e.type === "reportShared");
    }
  },
  methods: {
    orgShortName: FormatHelper.orgShortName,
    formatCurrency: FormatHelper.formatPoundToDisplay,
    formatSimpleDate: d => FormatHelper.formatDate(d),
    formatDate: d => FormatHelper.formatDateTimeCustom(d, "ddd D MMM, YYYY HH:mm"),
    formatStage: s => TypeHelper.formatSalesRecordStage(s).substring(4),
    formatToLocalDate: d => FormatHelper.formatDateToLocalDateTime(d),
    displayNameFromEmail: e => FormatHelper.displayNameFromEmail(e),
    text: t => (t ? t : "--"),

    updateCpmEmail(cpmId) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord.cpmId = cpmId;
      this.newSalesRecord.cpmEmail = this.cpms.find(c => c.cpmId === cpmId).email;
      this.$emit("update-details", this.newSalesRecord);
    },

    updateOpsEmail(opsId) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord.opsId = opsId;
      this.newSalesRecord.opsEmail = this.ops.find(c => c.opsId === opsId).email;
      this.$emit("update-details", this.newSalesRecord);
    },

    updateApmEmail(apmId) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord.apmId = apmId;
      this.newSalesRecord.apmEmail = this.apms.find(c => c.apmId === apmId).email;
      this.$emit("update-details", this.newSalesRecord);
    },

    updateStage(stage) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord.stage = stage;
      if (stage !== "CLOSED_LOST") {
        this.newSalesRecord.closedLostReason = null;
        this.newSalesRecord.closedLostDate = null;
      }
      if (stage !== "CLOSED_WON") {
        this.newSalesRecord.closedWonDate = null;
      }
      this.$emit("update-details", this.newSalesRecord);
    },

    updateClosedLostReason(reason) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord.closedLostReason = reason;
      this.$emit("update-details", this.newSalesRecord);
    },

    updateField(field, value) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord[field] = value;
      this.$emit("update-details", this.newSalesRecord);
    },

    updateClosedLost({ date, reason }) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord.closedLostReason = reason;
      this.newSalesRecord.closedLostDate = date;
      this.$emit("update-details", this.newSalesRecord);
    },

    updateClosedWon({ date }) {
      this.newSalesRecord = { ...this.salesRecord };
      this.newSalesRecord.closedWonDate = date;
      this.$emit("update-details", this.newSalesRecord);
    },

    updateClosedLostDate() {
      if (this.closedLostDate) {
        this.selectingClosedLostDate = false;
        this.updateField("closedLostDate", this.formatToLocalDate(this.closedLostDate));
      }
    },

    updateClosedWonDate() {
      if (this.closedWonDate) {
        this.selectingClosedWonDate = false;
        this.updateField("closedWonDate", this.formatToLocalDate(this.closedWonDate));
      }
    },

    updateNextActionDate() {
      if (this.nextActionDate) {
        this.selectingNextActions = false;
        this.updateField("nextActionDate", this.formatToLocalDate(this.nextActionDate));
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#sales-record-details {
  margin: -0.25rem;
  position: relative;
  overflow: visible !important;
  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35rem;
  }
  .details-field {
    padding: 0.25rem;
    font-size: 15px;
    margin-top: 0.5rem;
    display: flex;
    flex-flow: column;
    height: 4.2rem;
    label {
      margin-bottom: 2px;
    }
  }
  .details-field,
  .editable-field {
    label {
      text-transform: uppercase;
      font-size: 0.8rem;
      letter-spacing: 0.2px;
      color: #595b5c;
    }
  }
  .sales-link {
    position: absolute;
    top: -45px;
    left: -10px;
  }
  .selection-badge {
    border: 1px solid #0747a640;
  }
  .apm-field {
    margin-top: 16px !important;
  }
  .bg-hover-white {
    color: $color-font-headings;
    &:hover {
      background: whitesmoke !important;
    }
  }
  .closed-lost-reason-badge {
    color: #cf2d22f5;
    background: #ee30001f;
    border-color: #b91d1d3b !important;
  }
  .closed-won-reason-badge {
    color: white !important;
    background-color: #0f6e1bb9 !important;
    font-weight: 300 !important;
  }
}
</style>
