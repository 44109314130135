<template>
  <div id="organisation-events">
    <b-table :items="events" responsive :fields="fields" show-empty empty-text="No event activity">
      <template #cell(userEmail)="row">
        <b-link
          v-if="linkToDashboard(row.item, row.value)"
          v-b-tooltip="'Go to user dashboard'"
          :to="{ path: '/dashboard?userid=' + row.item.userId }"
          target="_blank"
        >
          {{ row.value }}
          <font-awesome-icon class="ml-1 rotate-45" :icon="['far', 'long-arrow-up']" />
        </b-link>
        <span v-else>{{ row.value }}</span>
      </template>
      <template #cell(emails)="row">
        <b-link
          v-if="linkToDashboard(row.item, row.value)"
          v-b-tooltip="'Go to user dashboard'"
          :to="{ path: '/dashboard?userid=' + row.item.userId }"
          target="_blank"
        >
          {{ row.value }}
          <font-awesome-icon class="ml-1 rotate-45" :icon="['far', 'long-arrow-up']" />
        </b-link>
        <span v-else-if="row.value !== 'null'">{{ row.value }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "OrganisationEvents",
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fields: [
        {
          key: "created",
          label: "Created",
          sortable: true,
          formatter: FormatHelper.formatDateTimeToMin
        },
        {
          key: "type",
          label: "Event Type",
          sortable: true,
          formatter: FormatHelper.formatCamelCaseToTitleCase
        },
        {
          key: "userEmail",
          label: "User",
          sortable: true
        },
        {
          key: "emails",
          label: "Shared With",
          formatter: v => (v ? v.join(", ") : ""),
          sortable: true
        },
        {
          key: "number",
          sortable: true
        }
      ]
    };
  },
  methods: {
    linkToDashboard(item, email) {
      return item.userId && email && email !== "null" && !email.includes("@reducer");
    }
  }
};
</script>

<style lang="scss">
#organisation-events {
  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  a {
    color: inherit;
  }
}
</style>
