<template>
  <div id="organisation-contracts">
    <b-table
      :items="contracts"
      :fields="tableFields"
      responsive
      show-empty
      empty-text="No contracts found"
    >
      <template #cell(contractId)="row">
        <b-link class="bg-light p-2 rounded" @click="$refs.contractModal.show(row.value)">
          <font-awesome-icon class="text-muted" :icon="['far', 'pencil']" />
        </b-link>
      </template>
    </b-table>
    <ContractEditor
      ref="contractModal"
      @contractUpdated="$emit('contract-updated')"
    ></ContractEditor>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import ContractEditor from "@/components/contracts/ContractEditor";

export default {
  name: "OrganisationContracts",
  components: { ContractEditor },
  props: {
    contracts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableFields: [
        { key: "contractId", label: "Edit" },
        { key: "product", formatter: FormatHelper.formatTitleCase, sortable: true },
        { key: "newSupplierName", label: "New Supplier" },
        { key: "state", sortable: true },
        { key: "commissionGross", formatter: FormatHelper.formatPoundToDisplay, sortable: true },
        { key: "signedDate", formatter: FormatHelper.formatDate, sortable: true },
        { key: "failedDate", formatter: FormatHelper.formatDate, sortable: true }
      ]
    };
  }
};
</script>

<style lang="scss">
#organisation-contracts {
  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
