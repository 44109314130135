<template>
  <div
    :class="'editable-field' + (editing ? ' editing shadow' : '')"
    @click="startEditing"
    @input.stop
    @keydown.enter="save"
    @keydown.esc="cancel"
  >
    <label>{{ label }}</label>
    <div v-if="!editing">
      <span>{{ text }}</span>
    </div>
    <div v-else-if="options">
      <b-select
        v-model="newValue"
        :options="formattedOptions"
        :text-field="textField"
        :value-field="valueField"
        size="sm"
      />
    </div>
    <div v-else>
      <b-input
        ref="textInput"
        v-model="newValue"
        :type="type"
        size="sm"
        no-wheel
        :required="type === 'email'"
        :pattern="type === 'email' ? emailPattern : null"
      />
    </div>
    <b-form-invalid-feedback :state="!errorMessage" class="mt-2 bg-white text-center border">
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <div class="editing-controls d-none">
      <font-awesome-icon
        class="text-muted mr-2 cancel-btn pointer"
        :icon="['fas', 'times-circle']"
        @click.stop="editing = false"
      />
      <font-awesome-icon class="pointer" :icon="['fas', 'check-circle']" @click.stop="save" />
    </div>
    <font-awesome-icon class="edit-icon d-none" :icon="['far', 'pencil']" />
  </div>
</template>

<script>
export default {
  name: "EditableField",
  props: {
    value: String,
    label: String,
    options: Array,
    type: String,
    textField: {
      type: String,
      default: "text"
    }, // for options
    valueField: String, // for options,
    textFormatter: Function,
    displayText: String
  },
  data() {
    return {
      editing: false,
      newValue: this.value,
      errorMessage: null
    };
  },
  computed: {
    selected() {
      if (this.options) {
        return this.formattedOptions.find(o => o[this.valueField] === this.value);
      } else return this.editing;
    },
    text() {
      if (this.selected && this.textFormatter) {
        return this.textFormatter(this.selected[this.textField]);
      } else if (this.selected) {
        return this.selected[this.textField];
      } else if (this.displayText) {
        return this.displayText;
      } else {
        return this.value ? this.value : "--";
      }
    },
    formattedOptions() {
      if (this.textFormatter) {
        return this.options.map(o => ({ ...o, text: this.textFormatter(o.email) }));
      }
      return this.options;
    },
    emailPattern() {
      return "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$";
    }
  },
  methods: {
    cancel() {
      this.editing = false;
      this.newValue = this.value;
    },
    save() {
      if (this.type === "email" && !this.$refs.textInput.checkValidity()) {
        this.errorMessage = "Please enter a valid email address.";
        return;
      }

      this.errorMessage = null;
      this.$emit("input", this.newValue);
      this.$emit("update", this.newValue);
      this.editing = false;
    },
    async startEditing() {
      this.editing = true;
      await this.$nextTick();
      if (this.$refs.textInput) {
        this.$refs.textInput.focus();
      }
    },
    isValidEmail(email) {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.editable-field {
  padding: 0.25rem;
  font-size: 15px;
  margin-top: 0.5rem;
  display: flex;
  flex-flow: column;
  height: 3.75rem;
  border-radius: 5px;
  position: relative;
  label {
    margin-bottom: 0;
  }
  &:hover {
    background: rgb(228, 228, 228);
    &:not(.editing) .edit-icon {
      display: block !important;
    }
  }
  &.editing {
    background: white;
    box-shadow: $light-shadow;
    .editing-controls {
      padding: 2px 5px;
      display: block !important;
      position: absolute;
      top: -22px;
      right: 0px;
      background: white;
      border-radius: 5px;
      .cancel-btn:hover {
        color: black !important;
      }
      .edit-icon {
        display: none !important;
      }
    }
  }
  .edit-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &:not(.editing) {
    label,
    div,
    input,
    span:hover {
      cursor: pointer;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
