<template>
  <b-container id="sales-record">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <Busy v-if="busy" primary text="Fetching deal..." />

    <p v-else-if="notFound">
      This organisation is not assigned to a CPM. Go to
      <b-link :to="{ name: 'sales' }">Sales Bible</b-link> to assign.
    </p>

    <div v-else-if="salesRecord">
      <div>
        <div
          id="top-section"
          :class="
            `main-container bg-light ${
              salesRecord.closedLostReason && salesRecord.nextActionDate ? 'overflow-auto' : ''
            }`
          "
        >
          <b-row>
            <b-col cols="7">
              <b-overlay :show="salesRecordBusy" spinner-type="grow">
                <SalesRecordDetails
                  :sales-record="salesRecord"
                  :organisation="organisation"
                  :cpms="cpms"
                  :apms="apms"
                  :ops="ops"
                  :events="events"
                  :saving="saving"
                  :latest-call="calls ? calls[1] : null"
                  @update-details="updateSalesRecord"
                />
              </b-overlay>
            </b-col>
            <b-col>
              <b-overlay :show="savingBusy" spinner-type="grow">
                <SavingsOverview
                  class="sub-container"
                  :saving="saving"
                  :sales-record="salesRecord"
                  @update-details="updateSalesRecord"
                />
              </b-overlay>
            </b-col>
          </b-row>
        </div>
        <div id="bottom-section" class="main-container mt-3">
          <b-row>
            <b-col cols="12">
              <b-tabs vertical pills class="bottom-nav">
                <b-tab lazy>
                  <template #title>
                    Notes
                    <b-badge class="py-1 ml-1 position-relative">
                      <b-overlay
                        :show="notesBusy"
                        spinner-type="grow"
                        spinner-small
                        no-wrap
                        rounded="sm"
                      />
                      {{ notes.length }}
                    </b-badge>
                  </template>
                  <b-overlay :show="notesBusy" spinner-type="grow">
                    <OrganisationNotes
                      :notes="notes"
                      @fetch-notes="fetchNotes"
                      class="sub-container"
                    />
                  </b-overlay>
                </b-tab>

                <b-tab lazy>
                  <template #title>
                    Contracts
                    <b-badge class="py-1 ml-1 position-relative">
                      <b-overlay
                        :show="contractsBusy"
                        spinner-type="grow"
                        spinner-small
                        no-wrap
                        rounded="sm"
                      />
                      {{ contracts.length }}
                    </b-badge>
                  </template>
                  <b-overlay :show="contractsBusy" spinner-type="grow">
                    <OrganisationContracts
                      :contracts="contracts"
                      class="sub-container"
                      @contract-updated="fetchContracts"
                    />
                  </b-overlay>
                </b-tab>

                <b-tab lazy>
                  <template #title>
                    Events
                    <b-badge class="py-1 ml-1 position-relative">
                      <b-overlay
                        :show="eventsBusy"
                        spinner-type="grow"
                        spinner-small
                        no-wrap
                        rounded="sm"
                      />
                      {{ events.length }}
                    </b-badge>
                  </template>
                  <b-overlay :show="eventsBusy" spinner-type="grow">
                    <OrganisationEvents :events="events" class="sub-container" />
                  </b-overlay>
                </b-tab>

                <b-tab lazy>
                  <template #title>
                    Links
                    <b-badge class="py-1 ml-1 position-relative">
                      <b-overlay
                        :show="linksBusy"
                        spinner-type="grow"
                        spinner-small
                        no-wrap
                        rounded="sm"
                      />
                      {{ links.length }}
                    </b-badge>
                  </template>
                  <b-overlay :show="linksBusy" spinner-type="grow">
                    <SalesRecordLinks
                      :salesRecords="links"
                      class="sub-container"
                      @add-link="addLink"
                      @remove-link="removeLink"
                    />
                  </b-overlay>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import SalesRecordDetails from "@/components/sales/SalesRecordDetails";
import OrganisationNotes from "@/components/sales/OrganisationNotes";
import OrganisationEvents from "@/components/sales/OrganisationEvents";
import SavingsOverview from "@/components/sales/SavingsOverview";
import OrganisationContracts from "@/components/sales/OrganisationContracts";
import SalesRecordLinks from "../../../components/sales/SalesRecordLinks";

export default {
  name: "SalesRecord",
  components: {
    Busy,
    SalesRecordDetails,
    OrganisationNotes,
    OrganisationEvents,
    SavingsOverview,
    OrganisationContracts,
    SalesRecordLinks
  },
  data() {
    return {
      title: "Deal",
      busy: false,
      notFound: false,
      organisationId: this.$route.params.organisationId,

      salesRecord: null,
      organisation: null,
      saving: null,
      events: [],
      cpms: [],
      ops: [],
      calls: [],
      contracts: [],
      notes: [],
      links: [],

      salesRecordBusy: false,
      savingBusy: false,
      cloudTalkBusy: false,
      contractsBusy: false,
      cpmsBusy: false,
      apmsBusy: false,
      opsBusy: false,
      eventsBusy: false,
      notesBusy: false,
      linksBusy: false
    };
  },
  created() {
    this.fetchSalesRecord();
    this.fetchSaving();
    this.fetchNotes();
    this.fetchContracts();
    this.fetchCpmUsers();
    this.fetchApmUsers();
    this.fetchOpsUsers();
    this.fetchEvents();
  },
  methods: {
    async fetchSalesRecord() {
      this.salesRecordBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}sales/records?organisationId=${this.organisationId}`)
        .then(response => {
          console.log("Deal:", response);
          this.salesRecord = response.data.salesRecords[0];
          if (this.salesRecord) {
            this.organisation = this.salesRecord.organisation;
            this.title += " - " + this.salesRecord.organisationName;
            this.fetchSalesRecordLinks();
          } else {
            this.notFound = true;
          }
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching deals data.", "warning");
          if (e.response.status == 404) {
            this.notFound = true;
          }
        })
        .finally(() => (this.salesRecordBusy = false));
    },

    async fetchSalesRecordLinks() {
      if (!this.salesRecord.salesRecordLinks || this.salesRecord.salesRecordLinks.length == 0) {
        return;
      }
      this.linksBusy = true;
      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}sales/records/query`, {
          organisationIds: this.salesRecord.salesRecordLinks,
          includeCommission: true,
          includeTotalAq: true,
          includeSoonestCed: true,
          includeLatestSavings: true
        })
        .then(response => {
          console.log("Sales record links:", response);
          this.links = response.data.salesRecords;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching organisation links.", "warning");
        })
        .finally(() => (this.linksBusy = false));
    },

    async fetchCloudTalk() {
      this.cloudTalkBusy = true;
      this.errorMessage = null;

      let client = await ApiHelper.http();
      await client
        .get(
          `${ApiHelper.endPoint()}cloudtalk/contacts?organisationId=${
            this.organisationId
          }&includeCalls=true&includeAgents=true`
        )
        .then(response => {
          console.log("CloudTalk contacts: ", response);
          this.calls = this.enrichedCalls(response.data);
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching CloudTalk data.", "warning");
        })
        .finally(() => (this.cloudTalkBusy = false));
    },

    async fetchCpmUsers() {
      this.cpmsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?cpmsOnly=true`)
        .then(response => {
          console.log("CPMs:", response);
          this.cpms = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching user data.", "warning");
        })
        .finally(() => (this.cpmsBusy = false));
    },

    async fetchApmUsers() {
      this.apmsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?apmsOnly=true`)
        .then(response => {
          console.log("APMs:", response);
          this.apms = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching user data.", "warning");
        })
        .finally(() => (this.apmsBusy = false));
    },

    async fetchOpsUsers() {
      this.opsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?opsOnly=true`)
        .then(response => {
          console.log("Ops:", response);
          this.ops = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching user data.", "warning");
        })
        .finally(() => (this.opsBusy = false));
    },

    async fetchEvents() {
      this.eventsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}organisations/${this.organisationId}/events`)
        .then(response => {
          console.log("Events: ", response);
          this.events = response.data.events;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching events data.", "warning");
        })
        .finally(() => (this.eventsBusy = false));
    },

    async fetchContracts() {
      this.contractsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}contracts/organisations/${this.organisationId}`)
        .then(response => {
          console.log("Contracts", response);
          this.contracts = response.data.contracts;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching contracts.", "warning");
        })
        .finally(() => (this.contractsBusy = false));
    },

    async fetchSaving() {
      this.savingBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}savings?organisationId=${this.organisationId}`)
        .then(response => {
          console.log("Savings: ", response);
          this.saving = response.data.savings[0];
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching savings data.", "warning");
        })
        .finally(() => (this.savingBusy = false));
    },

    async fetchNotes() {
      this.notesBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}organisations/${this.organisationId}/notes`)
        .then(response => {
          console.log("Notes:", response);
          this.notes = response.data.organisationNotes;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching notes.", "warning");
        })
        .finally(() => (this.notesBusy = false));
    },

    async updateSalesRecord(salesRecord, updateView = true) {
      let oldSalesRecord = { ...this.salesRecord };
      if (updateView) {
        this.salesRecord = salesRecord;
      }
      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}sales/records`, {
          salesRecords: [salesRecord]
        })
        .then(() => {
          if (updateView) {
            this.$set(this.salesRecord, "version", this.salesRecord.version + 1);
          }
          this.showMessage("Deal updated successfully.", "success");
        })
        .catch(e => {
          if (updateView) {
            this.salesRecord = oldSalesRecord;
          }
          console.error(e);
          if (e.response.status == 409) {
            this.showMessage(
              `Someone else has updated deal for ${salesRecord.organisationName}. Please refresh the page to see the latest version.`,
              "warning"
            );
          } else {
            this.showMessage("There was an issue updating the deal.", "warning");
          }
        });
    },

    enrichedCalls({ calls, contacts, agents }) {
      let contactsMap = [];
      for (const contact of contacts) {
        if (contact.ContactNumber) {
          contact.ContactNumber.forEach(number => {
            contactsMap[number.public_number] = contact;
          });
        }
      }
      let agentsMap = {};
      for (const agent of agents) {
        agentsMap[agent.id] = agent;
      }

      calls.forEach(call => {
        call.contact = contactsMap[call.public_external];
        call.agent = agentsMap[call.user_id];
      });

      return calls;
    },

    async addLink(organisationId) {
      this.updateSalesRecord({
        ...this.salesRecord,
        salesRecordLinks: [...(this.salesRecord.salesRecordLinks || []), organisationId]
      });
      await this.fetchSalesRecordLinks();
      const updatedLink = this.links.find(l => l.organisationId === organisationId);
      this.updateSalesRecord(
        {
          ...updatedLink,
          salesRecordLinks: [...(updatedLink.salesRecordLinks || []), this.organisationId]
        },
        false
      );
    },

    async removeLink(organisationId) {
      console.log(organisationId);
      this.updateSalesRecord({
        ...this.salesRecord,
        salesRecordLinks: this.salesRecord.salesRecordLinks.filter(id => id !== organisationId)
      });
      const linkToRemove = this.links.find(l => l.organisationId === organisationId);
      console.log(this.links, linkToRemove);
      await this.updateSalesRecord(
        {
          ...linkToRemove,
          salesRecordLinks: linkToRemove.salesRecordLinks.filter(id => id !== this.organisationId)
        },
        false
      );
      this.fetchSalesRecordLinks();
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
@include badge-styles;
#sales-record {
  max-width: 1500px;
  max-height: 100vh;

  // Containers height
  #top-section {
    max-height: 350px;
    .sub-container {
      max-height: 295px;
    }
  }
  #bottom-section {
    max-height: 550px;
    .sub-container {
      max-height: 480px;
    }
  }

  // Containers style
  .main-container {
    padding: 1rem;
    background: $color-grey-lighter5;
    border: $light-border;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01), 0 1px 2px #0000003d;
    max-height: 100%;
  }
  .sub-container {
    padding: 0.75rem;
    background: white;
    border-radius: 8px;
    box-shadow: $light-shadow;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  // Side navigation
  .bottom-nav {
    .nav-item:not(:first-child) {
      margin-top: 8px;
    }
    .nav-link {
      color: $color-font-headings;
      min-width: 10rem;
      &.active {
        background: white;
        box-shadow: $light-shadow;
      }
      &:not(.active):hover {
        background: $color-grey-lighter3;
      }
    }
  }

  // Unify label css
  .details-field label,
  .editable-field label,
  th {
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.2px;
    color: #595b5c;
  }

  .b-overlay .spinner-grow {
    color: $color-blue-lighter6 !important;
  }

  // Style tables
  td,
  tr {
    text-align: center;
    vertical-align: middle;
  }
  thead {
    background-color: whitesmoke;
    border-collapse: collapse;
  }

  // Style scrollbars
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }
  .rotate-45 {
    transform: rotate(45deg);
  }
}
</style>
