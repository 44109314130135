<template>
  <div id="organisation-notes">
    <b-textarea
      v-model="newNote.text"
      size="sm"
      placeholder="Type your note here..."
      no-resize
      maxlength="500"
      @click="createMode = true"
      class="mb-2"
      rows="3"
    />
    <div v-if="createMode">
      <div class="d-flex justify-content-between">
        <div class="w-25">
          <b-button
            variant="primary"
            size="sm"
            class="mb-2"
            :disabled="!newNote"
            @click="saveNote(newNote)"
            >Save</b-button
          >
          <b-button
            @click="
              createMode = false;
              newNote.text = null;
            "
            class="ml-2 mb-2"
            size="sm"
            variant="link"
            >Cancel</b-button
          >
        </div>
        <b-select
          class="note-type-select mb-2 w-25"
          v-model="newNote.type"
          :options="noteTypeOptions"
          size="sm"
        />
      </div>
    </div>

    <b-row
      v-for="note in notes"
      :key="note.noteId"
      :class="'note border-top px-1 py-3 pointer' + (isEditing(note) ? ' editing' : '')"
      @click="startEditing(note)"
    >
      <b-col cols="1" style="max-width: 4%">
        <font-awesome-icon :icon="['fal', 'file-alt']" class="mr-3" />
      </b-col>
      <b-col>
        <p v-if="note.type && !isEditing(note)" class="reducer-label my-1">
          {{ note.type.replace("_", " ") }}
        </p>
        <b-select
          v-else-if="isEditing(note)"
          class="note-type-select mb-2"
          v-model="editingType"
          :options="noteTypeOptions"
          size="sm"
        />
        <b-textarea
          v-if="isEditing(note)"
          v-model="editingText"
          size="sm"
          placeholder="Type your note here..."
          no-resize
          maxlength="500"
          class="mb-2"
          rows="5"
        />
        <span v-else class="note-text" @click.stop>{{ note.text }}</span>
        <div v-if="editing !== note.noteId" class="mt-2 d-flex justify-content-between">
          <span class="note-created text-muted">{{ displayName(note.createdByUserEmail) }}</span>
          <span class="note-created text-muted">{{ formatDate(note.createdDate) }}</span>
        </div></b-col
      >
      <b-col v-if="isEditing(note)" cols="12" class="pl-5">
        <b-button variant="primary" size="sm" class="ml-3" @click="saveNote(note)">Save</b-button>
        <b-button @click.stop="cancelEditing()" class="ml-2" size="sm" variant="link"
          >Cancel</b-button
        >
        <b-button class="float-right" size="sm" @click="deleteNote(note)">Delete</b-button>
      </b-col>
      <div class="note-controls d-none">
        <p v-if="editing !== note.noteId"><i>Click to edit or delete</i></p>
      </div>
    </b-row>
    <!-- <b-overlay no-wrap :show="busy" spinner-type="grow" rounded="sm" /> -->
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import TypeHelper from "@/helper/typehelper";

export default {
  name: "OrganisationNotes",
  props: {
    notes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      organisationId: null,
      newNote: {
        text: null,
        type: null
      },
      createMode: false,
      editing: "", // noteId
      editingText: null,
      editingType: null,
      noteTypeOptions: [
        { value: null, text: "Select note type" },
        ...TypeHelper.noteTypes.map(n => ({ value: n, text: FormatHelper.formatCoboldCase(n) }))
      ]
    };
  },
  created() {
    this.organisationId = this.$route.params.organisationId;
  },
  methods: {
    formatDate: d => FormatHelper.formatDateTimeCustom(d, "ddd D MMM, YYYY HH:mm"),
    displayName: FormatHelper.displayNameFromEmail,

    fetchNotes() {
      this.$emit("fetch-notes");
    },

    async saveNote(note) {
      if (note.text) {
        note.organisationId = this.organisationId;
        if (this.isEditing(note)) {
          note.text = this.editingText;
          note.type = this.editingType;
        }
        const client = await ApiHelper.http();
        await client
          .post(`${ApiHelper.endPoint()}organisations/notes`, {
            note: note
          })
          .then(() => {
            this.fetchNotes();
            this.newNote.text = null;
            this.createMode = null;
            this.cancelEditing();
          })
          .catch(e => {
            console.error(e);
            this.showMessage("There was an issue saving note.", "warning");
          });
      }
    },

    async deleteNote(note) {
      const client = await ApiHelper.http();
      await client
        .delete(`${ApiHelper.endPoint()}organisations/${this.organisationId}/notes/${note.noteId}`)
        .then(() => {
          this.fetchNotes();
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue saving note.", "warning");
        });
    },

    isEditing(note) {
      return this.editing === note.noteId;
    },

    cancelEditing() {
      this.editing = null;
      this.editingText = null;
      this.editingType = null;
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    startEditing(note) {
      if (!this.isEditing(note)) {
        this.editing = note.noteId;
        this.editingText = note.text;
        this.editingType = note.type ? note.type : null;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#organisation-notes {
  position: relative;
  padding-bottom: 0 !important;
  .note {
    color: $color-font-headings;
    position: relative;
    .note-text {
      font-size: 13.5px;
      cursor: text;
      white-space: pre-wrap;
    }
    .note-created {
      font-size: 11px;
    }
    .note-controls {
      font-size: 12px;
      position: absolute;
      top: 3px;
      right: 10px;
    }
    &:hover {
      background: whitesmoke;
      .note-controls {
        display: flex !important;
      }
    }
    .note-type-select {
      max-width: 150px;
    }
  }
}
</style>
